import { Pages }           from "./Pages";
import ReactDOM            from "react-dom";
import React        from "react";
import {PersonView} from "../components/PersonView";

const axios = require("axios").default;


export class PersonsDetail extends Pages {

    constructor(params) {
        super(params);

        this.data = window.diaryJsData;

        ReactDOM.render(
            <PersonView
                person={this.data.element}/>,
            document.getElementById('personsDetail')
        );

    }

    initPageHandlers() {
        super.initPageHandlers();
    }
}
